import React, { useState, useEffect, useRef } from 'react';
import { FaSync } from 'react-icons/fa';
import style from "./MarkKindredBit.module.css";
import axios from 'axios';
import aimg from '../../asset/marklogo.jpeg';
import { Container, Grid, Box, Button, Typography, Paper, TextField, CircularProgress } from '@mui/material';

const MarkKindredBit = () => {
    const [loading, setLoading] = useState(false);
    const outputBoxRef = useRef(null);
    const [question, setQuestion] = useState('');
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (outputBoxRef.current) {
            outputBoxRef.current.scrollTop = outputBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSubmit = async (e, exampleQuestion = null) => {
        if (e) e.preventDefault();
        
        let query = exampleQuestion || question;
        if (query == "What school i went for design studies?") {
           query = "What is Name Of My Design School from which i have Graduated in 1996?";
       }
        setLoading(true);
        const responseMessage = await fetchDataFromAPI(query);
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'user', content: query },
            { role: 'assistant', content: responseMessage }
        ]);
        setQuestion('');
        setLoading(false);
    };
    const handleRefresh = () => {
        setQuestion('');
        setMessages([]);
    };

   
    const fetchDataFromAPI = async (input) => {
        // const structuredInput = `Ensuring a comprehensive answer that covers the intent, emotions and personal information of the subject if human and adjunct information if a non human subject from the existing context, answer the following question ${input} . In case, any information is missing from the context, generate plausible information to fill in the blanks. Do not add any information about your answer. "`;

        // const structuredInput = `Ensuring a comprehensive answer that covers the intent, sentiments, emotions and personal preferences of the subject if human and adjunct information if an object from the existing context, answer the following question ${input} . In case, any information is missing from the context, generate plausible information to fill in the blanks.`


        // const structuredInput =`Ensuring a comprehensive answer that covers the intent, sentiments, emotions and personal preferences of the human subject and adjunct, ancillary information of the object gleaned and inferred from the existing context, answer the following question ${input}.`
    
        // const structuredInput =`${input}`

//         const structuredInput = `Answer the question ${input} with the context provided by first building a detailed profile of the subject ( user) from the context. This profile should cover relationships, interests, lifestyle activities, food habits, sentiments about the former and behavioural traits. In case, there is insufficient information, generate plausible fillers to create a complete coherent profile. Use the following general rules to augment the answer-
// 	1	If the question is based on the subject’s preferences, besides the direct answer, add an inferred sentiment about the preference based on the profile and if nothing found, create a plausible sentiment and also update the profile.
// 	2	If any references to places ( cities, restaurants ) look up details on those in the context and if none, add a footnote asking for more information to be added.
// 	3	If the question is a conditional, use the context to infer the answer based on the personality of the subject.

// `


// const structuredInput =`Create a detailed personality profile of Mark from the context. This profile should cover relationships, education, interests, lifestyle activities, food habits, sentiments about the former, likes, dislikes and behavioural traits. In case, there is insufficient information, generate plausible fillers to create a complete coherent profile. Use the following general rules to augment the answer- 1 If related to preferences, add an inferred sentiment about the preference based on the context and if nothing found, create a plausible sentiment and also update the profile. Lifestyle activities sentiments should be gauged on frequency with strong like attributed to the most frequent. 2 If any references to places ( cities, restaurants ) look up details on those in the context and if none, add a footnote asking for more information to be added. Create a formatted document of this profile for easy viewing with a separate document explaining the basis of each profile trait identified ,answer the following question ${input}`

const structuredInput = `Answer the question ${input} with the context provided by first building a detailed profile of the subject ( user) from the context. This profile should cover relationships, interests, lifestyle activities, food habits, sentiments about the former and behavioural traits. In case, there is insufficient information, generate plausible fillers to create a complete coherent profile. Use the following general rules to augment the answer-
	1	If the question is based on the subject’s preferences, besides the direct answer, add an inferred sentiment about the preference based on the profile and if nothing found, create a plausible sentiment and also update the profile.Lifestyle activities sentiments should be gauged on frequency with strong like attributed to the most frequent.
	2	If any references to places ( cities, restaurants ) look up details on those in the context and if none, add a footnote asking for more information to be added.
	3	If the question is a conditional, use the profile and context to infer the answer based on the personality of the subject.`


        let data = {
            input: structuredInput,
            config: {}, 
            kwargs: {}
        };
    
        let config = {
            method: 'post',
            url: '/api/rag_chain/invoke',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };
    
        try {
            console.log("config", config);
            const response = await axios.request(config);
            const output = response.data.output;
    
            console.log("API response output:", output); 
            
            // Try different answer keys
            const answerKey = 'Answer:';
            const answerIndex = output.indexOf(answerKey);
            if (answerIndex !== -1) {
                const result = output.substring(answerIndex + answerKey.length).trim().split("\n")[0];
                console.log("Result:", result);
                return result.length > 0 ? result : 'Unable to retrieve a valid answer. Please provide more details or clarify your question.';
            } else {
                return 'Please provide more details or clarify the question to get a better response.';
            }
            
            
           
        } catch (error) {
            console.error(error);
            return 'Error fetching data from API';
        }
    };
    
    
    
    return (
        <Container>
        <Box style={{ height: "152px" }} textAlign="center" py={0}>
            <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography className='title' style={{ position: "relative", bottom: "8px" }} variant="h4">Marks’s Kindred Bit</Typography>
                </Grid>
                <Grid item>
                    <img src={aimg} alt="Amit" style={{ height: "88px", width: "77px", marginRight: "20px" }} />
                </Grid>
            </Grid>
            <Typography style={{ position: "relative", fontSize: "14px", bottom: "40px", textAlign: "left" }} className="info" variant="body1" mt={2}>
                Entrepreneur, Chief Experience officer, Award winning design leader. <br />
                Passionate about AI and sustainability.
            </Typography>
        </Box>

        <Box className="about-amit" mb={2}>
            <Typography variant="h6" style={{ textAlign: "center", position:"relative" , bottom:"15px" ,fontWeight:"600"}}>Some things I’ve learnt about Mark</Typography>
            <Grid container spacing={2} justifyContent="center">
                {[
                    
                    { text: 'Nationality - US' },
                ].map((info, index) => (
                    <Grid item key={index}>
                        <Paper elevation={3} className="info-box">
                            <Typography style={{ fontSize: "13px" }} variant="body1">{info.text}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>

        <Paper style={{ backgroundColor: "rgb(243 247 245 / 40%)", height: "250px" }} className="output-box" ref={outputBoxRef} elevation={3} mb={3}>
            {loading ? (
                <CircularProgress />
            ) : (
                messages.length === 0 ? (
                    <Typography variant="h6" style={{ position: "relative", top: -35, textShadow: "1px 1px 1px white", padding: "22px" }}>
                        Hi, I’m Marks’s Kindred Bit. A pleasure to meet you all.
                    </Typography>
                ) : (
                    messages.map((message, index) => (
                        <Typography style={{ marginTop: "10px" }} key={index} className={message.role} variant="body1">
                            <strong>{message.role === 'user' ? 'You' : 'Kindred Bit'}: </strong>{message.content}
                        </Typography>
                    ))
                )
            )}
        </Paper>

        <Typography style={{ marginTop: "5px" ,textAlign:"center" }} variant="h6" className='p'>Ask me anything</Typography>

        <Box className="example-questions" mb={1}>
            <Grid container spacing={2} justifyContent="center">
                {[
                    'Where did i ski?',
                    'Where did i studied in 1996 ?',
                    'When did i visited "LOS TACOS No.1"?',
                    'Where is Weissfluhjoch Mountain Restaurant ?',
                    "What digital design aspects did you focus on during your studies at Harvard GSD?"
                ].map((example, index) => (
                    <Grid item key={index}>
                        <Button style={{ border: "2px solid blue", borderRadius: "50px" }}  onClick={() => handleSubmit(null, example)}>
                       
                            {example}
                            
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Box>

        <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="center" mb={2}>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Enter your Question here"
                />
            </Box>
            <Box display="flex" justifyContent="center" gap={2}>
                <Button variant="contained" color="primary" onClick={handleRefresh} >
                    <FaSync />
                </Button>
                <Button variant="contained" color="primary" type="submit">
                    Submit
                </Button>
            </Box>
        </form>
    </Container>
    )
}

export default MarkKindredBit
