// src/components/PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const storedUser = sessionStorage.getItem('authenticatedUser');
  const userDashboard = sessionStorage.getItem('userDashboard');

  // Check if user is authenticated and if they are trying to access their own dashboard
  const isAuthenticated = !!storedUser;
  const isCorrectDashboard = location.pathname === userDashboard;

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (!isCorrectDashboard) {
    return <Navigate to={userDashboard} />;
  }

  return children;
};

export default PrivateRoute;
