import React from 'react'

const Testing = () => {
    return (
        <div>
           <h1>testing</h1> 
        </div>
    )
}

export default Testing
