// import * as React from 'react';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import aimg from '../../asset/a2.jpeg';
// import ListSubheader from '@mui/material/ListSubheader';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import PeopleIcon from '@mui/icons-material/People';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import LayersIcon from '@mui/icons-material/Layers';
// import AssignmentIcon from '@mui/icons-material/Assignment';

// export const mainListItems = (handleListItemClick) => (

  
//   <React.Fragment>
//     <ListItemButton onClick={() => handleListItemClick('Dashboard')}>
//       <ListItemIcon>
//         {/* <DashboardIcon /> */}
//         <img style={{
//           width: "34px",
//           height: "29px",
//           marginLeft: "8px",
//         }} src={aimg} alt="" />

//       </ListItemIcon>
//       <ListItemText primary="Amit’s Kindred Bit" />
//     </ListItemButton>
//     <ListItemButton onClick={() => handleListItemClick('MarkKindredBit')}>
//       <ListItemIcon>
//         {/* <ShoppingCartIcon /> */}
//         <img style={{
//           width: "34px",
//           height: "29px",
//           marginLeft: "8px",
//         }} src={aimg} alt="" />

//       </ListItemIcon>
//       <ListItemText primary="Mark’s Kindred Bit" />
//     </ListItemButton>

//      <ListItemButton onClick={() => handleListItemClick('testing')}>
//       <ListItemIcon>
//         <PeopleIcon />
//       </ListItemIcon>
//       <ListItemText primary="testing" />
//     </ListItemButton>

//     {/*
//     <ListItemButton onClick={() => handleListItemClick('Reports')}>
//       <ListItemIcon>
//         <BarChartIcon />
//       </ListItemIcon>
//       <ListItemText primary="Reports" />
//     </ListItemButton>
//     <ListItemButton onClick={() => handleListItemClick('Integrations')}>
//       <ListItemIcon>
//         <LayersIcon />
//       </ListItemIcon>
//       <ListItemText primary="Integrations" />
//     </ListItemButton> */}
//   </React.Fragment>
// );

// export const secondaryListItems = (handleListItemClick) => (
//   <React.Fragment>
//     {/* <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton onClick={() => handleListItemClick('CurrentMonth')}>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton onClick={() => handleListItemClick('LastQuarter')}>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton onClick={() => handleListItemClick('YearEndSale')}>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton> */}
//   </React.Fragment>
// );


import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import aimg from '../../asset/marklogo.jpeg';
import PeopleIcon from '@mui/icons-material/People';

export const mainListItems = ({ handleListItemClick, selectedItem }) => (
  <React.Fragment>
    <ListItemButton
      onClick={() => handleListItemClick('Dashboard')}
      selected={selectedItem === 'Dashboard'}
    >
      <ListItemIcon>
        <img
          style={{
            width: '34px',
            height: '29px',
            marginLeft: '8px',
          }}
          src={aimg}
          alt=""
        />
      </ListItemIcon>
      <ListItemText primary="Mark’s Kindred Bit" />
    </ListItemButton>
    <ListItemButton
      onClick={() => handleListItemClick('MarkKindredBit')}
      selected={selectedItem === 'MarkKindredBit'}
    >
      <ListItemIcon>
        {/* <img
          style={{
            width: '34px',
            height: '29px',
            marginLeft: '8px',
          }}
          src={aimg}
          alt=""
        /> */}
      </ListItemIcon>
      {/* <ListItemText primary="testing" /> */}
    </ListItemButton>

    <ListItemButton
      onClick={() => handleListItemClick('testing')}
      selected={selectedItem === 'testing'}
    >
      <ListItemIcon>
        {/* <PeopleIcon /> */}
      </ListItemIcon>
      {/* <ListItemText primary="testing" /> */}
    </ListItemButton>



    <ListItemButton
      onClick={() => handleListItemClick('testing')}
      selected={selectedItem === 'testing'}
    >
      <ListItemIcon>
        {/* <PeopleIcon /> */}
      </ListItemIcon>
      {/* <ListItemText primary="testing" /> */}
    </ListItemButton>

    
  </React.Fragment>
);

export const secondaryListItems = ({ handleListItemClick, selectedItem }) => (
  <React.Fragment>
    {/* Additional secondary list items can go here */}
  </React.Fragment>
);
