
import Dashboard from './components/dashboard/Dashboard';
import * as React from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/login/UserLogin';
import MarkDashboard from './components/dashboard/MarkDashboard'
import PrivateRoute from './components/PrivateRoute';
import NotFound from './components/NotFound';
// import Dashboard from './Dashboard';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function App() {
  const [mode, setMode] = React.useState('light');

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

       
        <Router>
      <Routes>
        <Route path="/" element={<Login/>} />
      
          <Route
              path="/dashboard1"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/alpha/mark-v02"
              element={
                <PrivateRoute>
                  <MarkDashboard />
                </PrivateRoute>
              }
            />

<Route path="*" element={<NotFound/>} /> {/* Catch-all route for 404 */}
      </Routes>
    </Router>
        {/* <Dashboard /> */}


        
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
