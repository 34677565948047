// src/components/login/UserLogin.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, IconButton, InputAdornment, Button, Typography, Box } from '@mui/material';
import { Visibility, VisibilityOff, Person, Lock } from '@mui/icons-material';
import logo from '../../asset/Rainfall.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Static user credentials
  const users = {
    'amit@rainfall.one': { password: 'amit1234', dashboard: '/dashboard1' },
    'mark@rainfall.one': { password: 'mark1234', dashboard: '/alpha/mark-v02' }
  };

  // src/components/login/UserLogin.js
const handleLogin = (e) => {
    e.preventDefault();
    const user = users[username];
    if (user && user.password === password) {
      sessionStorage.setItem('authenticatedUser', username); // Store username
      sessionStorage.setItem('userDashboard', user.dashboard); // Store dashboard path
      setUsername('');  // Clear username field
      setPassword('');  // Clear password field
      navigate(user.dashboard);
    } else {
      alert('Invalid username or password');
    }
  };
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <header style={{ backgroundColor: '#2b2827', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <img src={logo} alt="Logo" style={{ marginLeft: '100px', height: '80px', width: '80px' }} />
        <Box>
          <Button color="inherit" style={{ marginRight: '10px' }}>Sign In</Button>
          <Button color="inherit">Sign Up</Button>
        </Box>
      </header>
      <Container maxWidth="xs" style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h5" gutterBottom style={{ color: 'black', marginBottom: '20px' }}>
          Login
        </Typography>
        <form onSubmit={handleLogin} style={{ width: '100%' }}>
          <Box mb={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Container>
      <footer style={{ backgroundColor: '#2b2827', color: 'white', padding: '20px', textAlign: 'center' }}>
        <Typography variant="body2">
        Copyright @ 2024 Rainfall Inc. All rights reserved.
        </Typography>
      </footer>
    </div>
  );
};

export default Login;
