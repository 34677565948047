import React, { useState, useEffect, useRef } from 'react';
import { FaSync } from 'react-icons/fa';
import './MainPage.css';
import axios from 'axios';
import aimg from '../../asset/a2.jpeg';
import { Container, Grid, Box, Button, Typography, Paper, TextField, CircularProgress } from '@mui/material';

const MainPage = () => {
    const [question, setQuestion] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const outputBoxRef = useRef(null);
    const formRef = useRef(null);

    const handleSubmit = async (e, exampleQuestion = null) => {
        if (e) e.preventDefault();
        
        let query = exampleQuestion || question;
        if (query == "What is the count of people following me?") {
           query = "What is the number of people who are follows me?";
       }
        setLoading(true);
        const responseMessage = await fetchDataFromAPI(query);
        setMessages(prevMessages => [
            ...prevMessages,
            { role: 'user', content: query },
            { role: 'assistant', content: responseMessage }
        ]);
        setQuestion('');
        setLoading(false);
    };

    const handleRefresh = () => {
        setQuestion('');
        setMessages([]);
    };

    const fetchDataFromAPI = async (input) => {
        const structuredInput = `answer concisely and without explanation in a single sentence with no additional information,"${input}"`;

        let data = {
            input: structuredInput,
            config: {}, 
            kwargs: {}
        };
        
        let config = {
            method: 'post',
            url: 'https://api.oracle.rainfall.one/rag_chain/invoke',
            // url:'https://api.oracle.rainfall.one/rag_chain/invoke',
            // url:'http://localhost:2000/rag_chain/invoke1',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

       

    //     try {
    //         const response = await axios.request(config);
    //         const output = response.data.output;
    //         const answerKey1 = '\nAnswer:\n\n';
    //         const answerKey2 = '\nAnswer:';
    //         const answerKey3 = '\nAnswer:\n';


    //         const answerIndex1 = output.indexOf(answerKey1);
    //         const answerIndex2 = output.indexOf(answerKey2);
    //         const answerIndex3 = output.indexOf(answerKey3);
            
            
    //         if (answerIndex1 !== -1) {
    //             // alert("ok" )
    //             const result = output.substring(answerIndex1 + answerKey1.length).split("\n");
    //             if(result.length > 0){
    //                 return output.substring(answerIndex1 + answerKey1.length).split("\n")[0];
    //             }

    //             else{

    //                 return output.substring(answerIndex1 + 7).trim();
    //             }
               
    //         } 

    //            else if(answerIndex2 !== -1  && answerKey3 !== "") {
    //             // console.log( answerIndex2)
    //             // console.log(answerKey2)
    //             console.log(answerIndex3)
    //             console.log(answerKey3)
    //             const result = output.substring(answerIndex2 + answerKey2.length).split("\n");
    //             if(result.length > 0){
    //                 return output.substring(answerIndex2 + answerKey2.length).split("\n")[0];
    //             }

    //             else{

    //                 return output.substring(answerIndex2 + 7).trim();
    //             }
            
           
    //     }

    //     else if(answerIndex3 !== -1 ) {
    //         alert("ok"  )
    //         // alert("answer4 key 3" ,answerKey3 )
    //         // alert("answer4 key 3" ,output )
    //         return output.substring(answerIndex3 + 15).trim();

    //         const result = output.substring(answerIndex3 + answerKey3.length).split("\n");
    //         if(result.length > 0){
    //             return output.substring(answerIndex3 + answerKey3.length).split("\n")[0];
    //         }

    //         else{

    //             return output.substring(answerIndex3 + 15).trim();
    //         }
    
    // }
    //     else {
    //         return 'No valid answer found in response';
    //     }
    // }
    //     catch (error) {
    //         console.error(error);


    //         return 'Error fetching data from API';
    //     }


    try {
        const response = await axios.request(config);
        const output = response.data.output;
    
        console.log("API response output:", output); 
    
        const answerKey1 = '\nAnswer:\n\n';
        const answerKey2 = '\nAnswer:';
        const answerKey3 = '\nAnswer:\n';
    
        const answerIndex1 = output.indexOf(answerKey1);
        const answerIndex2 = output.indexOf(answerKey2);
        const answerIndex3 = output.indexOf(answerKey3);
    
        console.log("Index of answerKey1:", answerIndex1); 
        console.log("Index of answerKey2:", answerIndex2); 
        console.log("Index of answerKey3:", answerIndex3); 
    
        if (answerIndex1 !== -1) {
            const result = output.substring(answerIndex1 + answerKey1.length).split("\n")[0].trim();
            console.log("Result from answerKey1:", result); 
            if (result.length > 0) {
                return result;
            }
        } 
        if (answerIndex2 !== -1) {
            const result = output.substring(answerIndex2 + answerKey2.length).split("\n")[0].trim();
            console.log("Result from answerKey2:", result); 
            if (result.length > 0) {
                return result;
            }
        } 
        if (answerIndex3 !== -1) {
            const result = output.substring(answerIndex3 + answerKey3.length).split("\n")[0].trim();
            console.log("Result from answerKey3:", result); 
            if (result.length > 0) {
                return result;
            }
        }
    
        return 'No valid answer found in response';
    } catch (error) {
        console.error(error);
        return 'Error fetching data from API';
    }
    
    };

 // const fetchDataFromAPI = async (input) => {
    //     const structuredInput = `answer concisely and without explanation in a single sentence with no additional information,"${input}"`;

    //     let data = {
    //         input: structuredInput,
    //         config: {}, 
    //         kwargs: {}
    //     };

    //     let config = {
    //         method: 'post',
    //         url: '/api/rag_chain/invoke',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: JSON.stringify(data)
    //     };

    //     try {
    //         console.log("config",config);
    //         const response = await axios.request(config);
    //         const output = response.data.output;
        
    //         console.log("API response output:", output); 
        
    //         const answerKey1 = '\nAnswer:\n\n';
    //         const answerKey2 = '\nAnswer:';
    //         const answerKey3 = '\nAnswer:\n';
        
    //         const answerIndex1 = output.indexOf(answerKey1);
    //         const answerIndex2 = output.indexOf(answerKey2);
    //         const answerIndex3 = output.indexOf(answerKey3);
        
           
    //         if (answerIndex1 !== -1) {
    //             const result = output.substring(answerIndex1 + answerKey1.length).split("\n")[0].trim();
    //             console.log("Result from answerKey1:", result); 
    //             if (result.length > 0) {
    //                 return result;
    //             }
    //         } 
    //         if (answerIndex2 !== -1) {
    //             const result = output.substring(answerIndex2 + answerKey2.length).split("\n")[0].trim();
    //             console.log("Result from answerKey2:", result); 
    //             if (result.length > 0) {
    //                 return result;
    //             }
    //         } 
    //         if (answerIndex3 !== -1) {
    //             const result = output.substring(answerIndex3 + answerKey3.length).split("\n")[0].trim();
    //             console.log("Result from answerKey3:", result); 
    //             if (result.length > 0) {
    //                 return result;
    //             }
    //         }
        
    //         return 'No valid answer found in response';
    //     } catch (error) {
    //         console.error(error);
    //         return 'Error fetching data from API';
    //     }
        
    //     };
    




    useEffect(() => {
        if (outputBoxRef.current) {
            outputBoxRef.current.scrollTop = outputBoxRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <Container>
            <Box style={{ height: "152px" }} textAlign="center" py={0}>
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography className='title' style={{ position: "relative", bottom: "8px" }} variant="h4">Amit’s Kindred Bit</Typography>
                    </Grid>
                    <Grid item>
                        <img src={aimg} alt="Amit" style={{ height: "88px", width: "77px", marginRight: "20px" }} />
                    </Grid>
                </Grid>
                <Typography style={{ position: "relative", fontSize: "14px", bottom: "40px", textAlign: "left" }} className="info" variant="body1" mt={2}>
                    Entrepreneur, CEO, Speaker, Investor, Itinerant and Visionary. <br />
                    Proponent of UEI and firm believer of individual privacy.
                </Typography>
            </Box>

            <Box className="about-amit" mb={2}>
                <Typography variant="h6" style={{ textAlign: "center", position:"relative" , bottom:"15px" ,fontWeight:"600"}}>Some things I’ve learnt about Amit</Typography>
                <Grid container spacing={2} justifyContent="center">
                    {[
                        { text: 'Longest residence - San Francisco' },
                        { text: 'Current favourite - New York' },
                        { text: 'Allergies - Chocolate/Milk' },
                        { text: 'Preferred Airline - United' },
                        { text: 'Nationality - US' },
                    ].map((info, index) => (
                        <Grid item key={index}>
                            <Paper elevation={3} className="info-box">
                                <Typography style={{ fontSize: "13px" }} variant="body1">{info.text}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <Paper style={{  height: "250px" }} className="output-box" ref={outputBoxRef} elevation={3} mb={3}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    messages.length === 0 ? (
                        <Typography variant="h6" style={{ position: "relative", top: -35, textShadow: "1px 1px 1px white", padding: "22px" }}>
                            Hi, I’m Amit’s Kindred Bit. A pleasure to meet you all.
                        </Typography>
                    ) : (
                        messages.map((message, index) => (
                            <Typography style={{ marginTop: "10px" }} key={index} className={message.role} variant="body1">
                                <strong>{message.role === 'user' ? 'You' : 'Assistant'}: </strong>{message.content}
                            </Typography>
                        ))
                    )
                )}
            </Paper>

            <Typography style={{ marginTop: "5px",textAlign:"center" }} variant="h6" className='p'>Ask me anything</Typography>

            <Box className="example-questions" mb={1}>
                <Grid container spacing={2} justifyContent="center">
                    {[
                        'What is my favorite city?',
                        'Where would I go in New York ?',
                        'Where did I go last ?',
                        'What is my last location?',
                        "What is the count of people following me?"
                    ].map((example, index) => (
                        <Grid item key={index}>
                            <Button style={{ border: "2px solid blue", borderRadius: "50px" }} onClick={() => handleSubmit(null, example)}>
                                {example}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <form onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="center" mb={2}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Enter your Question here"
                    />
                </Box>
                <Box display="flex" justifyContent="center" gap={2}>
                    <Button variant="contained" color="primary" onClick={handleRefresh}>
                        <FaSync />
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </Box>
            </form>
        </Container>
    );
};

export default MainPage;
